<template>
  <div class="applications">
    <Title label="Заявки" border />
    <div class="applications__header">
      <div>Заявка</div>
      <div class="applications__event">Мероприятие</div>
      <div class="applications__nominations">Смена/номинация</div>
      <div>Дата проведения</div>
      <div>Статус</div>
      <div>Действие</div>
      <div class="applications__event">ID</div>
    </div>
    <Preloader v-if="isListLoading" />
    <div class="applications__status" v-else>
      <div class="applications__table" v-if="statementsList.length > 0">
        <StatusApplication
          v-for="(item, index) in statementsList.filter((i) => {
            return i.status.key !== 45;
          })"
          :key="uniqueId + index"
          :data="item"
          review
        />
      </div>
      <NoApplications v-else class="applications__empty" />
    </div>
  </div>
</template>

<script>
import StatusApplication from "@/components/ApplicationsSection/StatusApplication";
import Title from "@/components/Blocks/Title";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";
import Preloader from "@/components/Blocks/Preloader";
import NoApplications from "@/components/ApplicationsSection/NoApplications";
import uniqueString from "unique-string";

export default {
  name: "Applications",
  components: { NoApplications, Preloader, Title, StatusApplication },
  computed: {
    ...mapState(["statementsList"]),
  },
  data() {
    return {
      uniqueId: uniqueString(),
      isListLoading: false,
    };
  },
  methods: {
    loadStatementsLit() {
      this.isListLoading = true;
      createRequest(requestConfigs.GETStatementList)
        .then((response) => {
          this.$store.commit("setStatementsList", response.data);
        })
        .finally(() => {
          this.isListLoading = false;
        });
    },
  },
  beforeMount() {
    this.loadStatementsLit();
  },
};
</script>

<style lang="scss">
.applications {
  height: 100%;
  &__header {
    margin: 40px 0 17px 0;
    display: grid;
    align-items: center;
    grid-template-columns: 10% 15% 20% 18% 17% 15% 5%;
    padding: 0px 16px;
    & > div {
      font-weight: 600;
    }
    & > div {
      justify-self: start;
      padding-left: 15px;
    }

    @include adaptive(desktop-mid) {
      display: none;
    }
  }
  &__event {
    font-weight: 600;
    line-height: 2rem;
  }
  &__banner {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    line-height: 2rem;
    color: var(--banner--color);
    font-weight: 600;
    & li {
      display: flex;
      gap: 5px;
      &:after {
        content: "\e90e";
        font-family: icomoon;
        position: relative;
        font-size: 1rem;
        top: 1px;
      }
    }
  }
  &__status {
    display: flex;
    flex-direction: column;
    height: 100%;
    & > div {
      flex: 0 0 55px;
    }
  }
  &__empty {
    height: 100%;
  }
}
</style>
